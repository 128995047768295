<template>
  <v-container fluid>
    <div>
      <h1 class="text-h4">{{ $lang.header.roleDetails }}</h1>
      <v-divider class="my-2"></v-divider>
      <v-row wrap no-gutters class="pb-4">
        <v-col
          cols="12"
          sm="12"
          md="8"
        >
          <v-card class="pa-2">
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-row wrap no-gutters justify="space-between" class="pb-1">
                <v-col
                  cols="12"
                  class="pr-sm-1"
                >
                  <v-text-field
                    v-model="role.name"
                    outlined
                    dense
                    :label="$lang.labels.name"
                    :hint="formatHint"
                    :persistent-hint="isEdit"
                    required
                    class="required-asterisk"
                    :rules="[v => !!v || $lang.labels.required, v => (v && v.length > 1 && v.length <= 250) || $lang.errors.nameTooShortOrLong]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters wrap justify="space-between">
                <v-col
                  cols="6"
                  class="text-left"
                >
                  <div class="d-inline-flex align-center">
                    <v-btn
                      class="button-default-width"
                      color="secondary"
                      :to="{ name: 'roles', params: { lang: $lang.current_lang } }"
                    >
                      <v-icon
                        right
                        dark
                        class="ma-0"
                      >
                        mdi-arrow-left
                      </v-icon>
                      <span class="ml-1">{{ $lang.actions.back }}</span>
                    </v-btn>
                    <action-button-with-confirmation
                      v-if="isEdit"
                      :action-text="$lang.actions.areYouSureYouWantToDelete"
                      :action-text-suffix="role.name"
                      :title="$lang.actions.delete"
                      type="role"
                      :is-disabled="!userCanDelete"
                      :button-text="$lang.actions.delete"
                      :button-color="'error'"
                      :data-cy="'role-delete'"
                      :forced-option="true"
                      :trigger-force-logic="triggerForceLogic"
                      class="ml-2"
                      :regular-delete-errors-usages="regularDeleteErrorsUsages"
                      :regular-delete-errors-resources="regularDeleteErrorsResources"
                      :regular-delete-errors-users="regularDeleteErrorsUsers"
                      :delete-success="deleteSuccess"
                      @submit="deleteRoleFunct($event)"
                      @closeAfterDelete="$router.push({ name: 'roles' })"
                      @closeDialog="''"
                    />
                  </div>
                </v-col>
                <v-col
                  cols="6"
                  class="text-right"
                >
                  <v-btn
                    :disabled="!valid || lock || !isEdit"
                    color="accent"
                    class="mr-2 button-default-width color-accent-text"
                    @click="copyCreateResource(role, 'roleCreate', $router, '', $options.filters.formatDateTime(new Date()))"
                  >
                    {{ $lang.actions.copyCreate }}
                  </v-btn>
                  <v-btn
                    :disabled="!valid || lock"
                    color="success"
                    class="button-default-width"
                    @click="submit()"
                  >
                    {{ $lang.actions.submit }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import {
  createRoleUsingPOST as createRole, deleteRoleUsingDELETE as deleteRole,
  getRoleByIdUsingGET as getRole, updateRoleUsingPUT as updateRole
} from '@/utils/api'
import ActionButtonWithConfirmation from '@/components/ui/ActionButtonWithConfirmation.vue'
import { mapActions } from 'vuex'
import { copyCreateResource } from '@/utils/helpers'

export default {
  components: { ActionButtonWithConfirmation },
  data() {
    return {
      err: '',
      success: '',
      valid: false,
      loading: false,
      lock: false,
      role: {
        id: '',
        name: '',
        isAssignableToUsers: false,
        isSystem: false,
        createdOn: '',
        modifiedOn: ''
      },
      isSuperUser: false,
      userCanDelete: false,
      triggerForceLogic: false,
      regularDeleteErrorsUsages: [],
      deleteSuccess: false,
      isEdit: false,
      regularDeleteErrorsResources: [],
      regularDeleteErrorsUsers: []
    }
  },
  computed: {
    formatHint() {
      return `${this.$lang.labels.createdOn}: ${this.$options.filters.formatDateTime(this.role.createdOn)}, ${this.$lang.labels.modifiedOn}: ${this.$options.filters.formatDateTime(this.role.modifiedOn)}, ID: ${this.role.id}`
    }
  },
  created() {
    this.isEdit = this.$route.name === 'roleEdit'

    let user = null

    if (localStorage.userData) {
      user = JSON.parse(localStorage.userData)

      this.isSuperUser = !!user.roles.find((x) => x.name === 'SUPER_USER')
      this.userCanDelete = !!user.roles.find((x) => x.name === 'ROLE_DELETER') || this.isSuperUser
    }
    if (this.isEdit && this.$route.params.id) {
      this.loading = true
      getRole({ id: this.$route.params.id })
        .then((res) => {
          this.role = res.data.data

          this.loading = false
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })
          this.loading = false
        })
    } else {
      if (this.$route.params.copy) {
        this.role = this.$route.params.copy
        this.loading = false
      } else {
        this.loading = false
      }
    }
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),
    copyCreateResource,
    deleteRoleFunct(isForced = false) {
      this.triggerForceLogic = false
      this.regularDeleteErrorsUsages = []
      this.deleteSuccess = false
      deleteRole({ id: this.role.id, force: isForced })
        .then((res) => {
          if (res.status === 200) {
            this.deleteSuccess = true
            this.regularDeleteErrorsUsages = res.data.data.usages
          } else {
            this.triggerForceLogic = true
            this.regularDeleteErrorsUsages = res.response.data.data.usages
            this.regularDeleteErrorsResources = res.response.data.data.relatedResources
            this.regularDeleteErrorsUsers = res.response.data.data.relatedUsers
          }
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    async submit() {
      this.lock = true

      let res = null

      try {

        res = this.isEdit ? await updateRole({ id: this.role.id, body: { name: this.role.name } }) : await createRole({ body: this.role })

        if (res && res.status !== 200) {
          const errorMessage = res?.response?.data?.statusText || (this.isEdit ? this.$lang.errors.roleUpdate : this.$lang.errors.roleCreate)

          this.addSnackbar({
            message: errorMessage,
            timeout: 5000,
            color: 'error'
          })
          this.lock = false

          return
        }
        const successMessage = this.isEdit ? this.$lang.success.roleUpdated : this.$lang.success.roleCreated

        this.addSnackbar({
          message: successMessage,
          timeout: 5000,
          color: 'success'
        })
        this.$router.push({
          name: 'roles',
          params: {
            message: successMessage
          }
        })

      } catch (err) {
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })
        this.lock = false
      }
    }
  }
}
</script>
